import React from 'react'
import { graphql } from 'gatsby'
import ArticlesIndex from '../components/ArticlesIndex'
import Layout from '../components/layout'

const IndexPage = ({
  data: { allMarkdownRemark: { edges: articles, totalCount } }
}) => (
  <Layout>
    <ArticlesIndex
      categories={articles.reduce(
        (categories, { node: { frontmatter: { category } } }) =>
          categories.indexOf(category) !== -1
            ? categories
            : [...categories, category],
        []
      ).sort()}
      articles={articles}
    />
  </Layout>
)

export const query = graphql`
  fragment pageFields on MarkdownRemark {
    id
    frontmatter {
      category
      title
      date
      summary
      thumbnail {
        relativePath
        childImageSharp {
          resolutions(height: 325) {
            ...GatsbyImageSharpResolutions
          }
        }
      }
      authorName
      authorDescription
      authorAvatar {
        relativePath
        childImageSharp {
          resolutions(height: 50, width: 50) {
            ...GatsbyImageSharpResolutions
          }
        }
      }
      headerImage {
        relativePath
        childImageSharp {
          resolutions(width: 1280) {
            ...GatsbyImageSharpResolutions
          }
        }
      }
    }
    html
    fields {
      slug
      tags
    }
  }

  query IndexQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          ...pageFields
        }
      }
    }
  }
`

export default IndexPage
